import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "styled-components";
import { useDisconnect } from "wagmi";
import { allUsersRoute, host, msguserIdRemove, invitationCount } from "../utils";
import ChatContainer from "../components/ChatContainer";
import Contacts from "../components/Contacts";
import Welcome from "../components/Welcome";
import MenuBar from "../assets/menu.png"
import './Chat.css';

export default function Chat() {
  const navigate = useNavigate();
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const [msgCount, setMsgCount] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(window);
  const [inviteCount, setinviteCount] = useState(0);
  const [invitationData, setInvitationData] = useState({});
  const { disconnect } = useDisconnect();

  useEffect(async () => {
    checkWalletConnection();
    hideShow();
    function handleResize() {
      setWindowDimensions(window);
      hideShow();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const checkWalletConnection = async () => {
    if (window.location.href.split('?q=')[1]) {
      localStorage.setItem('log-email', window.location.href.split('?q=')[1]);
    }
    let isConnected = localStorage.getItem('wagmi.connected');
    if (isConnected !== 'true') {
      await disconnect();
      const ipAddress = localStorage.getItem('ip');
      const logEmail = localStorage.getItem('log-email');
      localStorage.clear();
      localStorage.setItem('ip', ipAddress);
      if (logEmail) {
        localStorage.setItem('log-email', logEmail);
      }
      navigate('/metamask');
    }

    if (!localStorage.getItem('chat-app-current-user')) {
      navigate("/metamask");
    } else {
      setCurrentUser(await JSON.parse(localStorage.getItem('chat-app-current-user')));
    }
  }

  useEffect(async () => {
    if (currentUser) {
      var userList = await axios.get(`${allUsersRoute}?user_id=${currentUser._id}`);
      let usersData = userList.data.data;
      if (usersData) {
        usersData = usersData?.filter((item) => currentUser._id != item._id);

        let currentChatWith = localStorage.getItem('current-chat-with');
        if (currentChatWith) {
          currentChatWith = JSON.parse(currentChatWith);
          let receiverData = await usersData?.filter((item) => currentChatWith._id == item._id)[0];
          if (!receiverData) {
            localStorage.removeItem('current-chat-with');
            setCurrentChat();
          } else {
            setCurrentChat(currentChatWith);
          }
        }

        if (invitationData && invitationData.is_accept) {
          showChatAfterAcceptInvitation(usersData);
        }
        setUnreadCount(usersData);
        setContacts(usersData);
      }
    }

    socket.current = io(host);
    if (currentUser) {
      socket.current.emit("add-user", currentUser._id);
    }
  }, [currentUser]);

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieve", async (msg) => {
        const data = await JSON.parse(
          localStorage.getItem('chat-app-current-user')
        );
        const currentChat = await JSON.parse(
          localStorage.getItem('current-chat-with')
        );
        if (msg.to == data._id) {
          var msgRe = await axios.post(msguserIdRemove, {
            id: currentChat._id,
            newMsgUserId: data._id
          });
          setTimeout(async () => {
            await handleBlinking();
          }, 1000);
        }
        //  else {
        //   setTimeout(async () => {
        //     await handleBlinking();
        //   }, 1000);
        // }
      });
    }
    if (currentUser) {
      invitationCounts();
    }
  }, [socket.current]);

  const loggedInUserDetailsGet = async () => {
    setCurrentUser(await JSON.parse(localStorage.getItem('chat-app-current-user')));
  }

  /**
   * Invitation notification counts
   */
  const invitationCounts = async () => {
    getInvitationCount();
    socket.current.on('invitation-get', async (resData) => {
      if (resData.receiver_id == currentUser._id) {
        getInvitationCount();
        loggedInUserDetailsGet();
      }
    });
  }

  /**
   * Get counts from api
   */
  const getInvitationCount = async () => {
    let { data } = await axios.get(invitationCount + `?user_id=${currentUser._id}`);
    setinviteCount(data.total_invitation);
  }

  const showChatAfterAcceptInvitation = (usersData) => {
    let filerData = usersData.filter(({ _id }) => _id === invitationData.receiver_id)[0];
    handleChatChange(filerData);
    setInvitationData(null);
  }

  /**
   * This func. is calling from Invitation file
   * Get invited users
   */
  const getInvitedUsers = (resData) => {
    setInvitationData(resData);
    getInvitationCount();
    loggedInUserDetailsGet();
  };

  const handleBlinking = async () => {
    var userList = await axios.get(`${allUsersRoute}?user_id=${currentUser._id}`);
    let usersData = userList.data.data;
    usersData = usersData.filter((item) => currentUser._id != item._id);
    setUnreadCount(usersData);
    setContacts(usersData);
  };

  const setUnreadCount = (data) => {
    let count = 0;
    setMsgCount(count);
    data.map((item) => {
      if (item.newMsgUserId.length > 0 && item.newMsgUserId.includes(currentUser._id)) {
        count += 1;
        setMsgCount(count);
      }
    })
  }

  const handleChatChange = async (chat) => {
    localStorage.setItem('current-chat-with', JSON.stringify(chat));
    setCurrentChat(chat);
    if (chat?.newMsgUserId && chat.newMsgUserId.length > 0) {
      var userList = await axios.get(`${allUsersRoute}?user_id=${currentUser._id}`);
      let usersData = userList.data.data;
      usersData = usersData.filter((item) => currentUser._id != item._id);
      setUnreadCount(usersData);
      setContacts(usersData);
    }
    hideShow('contactclick');
  };

  const hideShow = (flag = '') => {
    if (flag === 'menuclick') {
      setIsVisible(!isVisible);
    } else if (windowDimensions.innerWidth > 768 && windowDimensions.innerWidth < 820 && flag === 'contactclick') {
      setIsVisible(!isVisible);
    } else if (windowDimensions.innerWidth > 820) {
      setIsVisible(isVisible);
    } else {
      setIsVisible(!isVisible);
    }
  }

  return (
    <>
      <Container>
        {/* <button onClick={hideShow}>ok</button> */}
        <div className={isVisible ? "container w_c" : "container"}>
          <button className="w_btn" onClick={() => hideShow('menuclick')}>
            <img className="menuBarCls" src={MenuBar} />
            {
              msgCount ? <div className="count-bg"><span>{msgCount}</span></div> : ''
            }
          </button>
          <Contacts
            contacts={contacts}
            socket={socket}
            inviteCount={inviteCount}
            getInvitedUsers={getInvitedUsers}
            changeChat={handleChatChange}
            isVisible={isVisible}
            hideShow={hideShow}
          />
          {currentChat === undefined ? (
            <Welcome />
          ) : (
            <ChatContainer currentChat={currentChat} socket={socket} isVisible={isVisible} />
          )}
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
 // height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #6d4cd4;
  .container {
    height: 100vh;
    width: 100vw;
    background-color: #00000076;
    display: grid;
    grid-template-columns: 25% 75%;
    @media screen and (max-width: 991px) {
      grid-template-columns: 35% 65%;
    }
    @media screen and (max-width: 767px){
      height: 100vh;
      display: flex; 
    }
  }
`;
