import React from "react";
import '@rainbow-me/rainbowkit/styles.css';
import {
    RainbowKitProvider,
    darkTheme as RainDark,
    connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import { createClient, configureChains, WagmiConfig } from 'wagmi';
import {
    metaMaskWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import Logout from './Logout';
import Chat from '../pages/Chat';

export default function WagmiConigComponent(props) {
    const { chains, provider } = configureChains([mainnet], [publicProvider()]);

    const connectors = connectorsForWallets([
        {
            groupName: 'Recommended',
            wallets: [
                metaMaskWallet({ chains }),
            ],
        },
    ]);
    const wagmiClient = createClient({
        autoConnect: true,
        connectors,
        provider,
    });

    const renderComponent = () => {
        switch (props.title) {
            case 'logout': return <Logout />;
            case 'chat': return <Chat />;
        }
    }

    return (
        <WagmiConfig client={wagmiClient}>
            {renderComponent()}
        </WagmiConfig>
    )
}