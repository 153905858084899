import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import { getInvitation } from "../../utils/APIRoutes";
import SendInvitation from './SendInvitation';
import { sendInvitation } from "../../utils/APIRoutes";
import "./Invitation.css";

function Invitation({ socket, getInvitedUsers, setInvitationModal }) {
    const [isShowSendInvitationModal, setSendInvitationModal] = useState(false);
    const [invitationData, setInvitationData] = useState({});

    const toastOptions = {
        position: "top-right",
        autoClose: 5000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
    };

    useEffect(async () => {
        let loggedInUser = JSON.parse(localStorage.getItem("chat-app-current-user"));
        if (!isShowSendInvitationModal) {
            socket.current.on('invitation-get', async (resData) => {
                if (resData.receiver_id == loggedInUser._id) {
                    getInvitations();
                }
            });
            getInvitations();
        }
    }, [isShowSendInvitationModal]);

    const getInvitations = async () => {
        let loggedInUser = JSON.parse(localStorage.getItem("chat-app-current-user"));
        if (loggedInUser) {
            const response = await axios.get(getInvitation + `?user_id=${loggedInUser._id}`);
            if (response.data.status) {
                setInvitationData(response.data);
            }
        }
    }

    const acceptOrReject = async (params) => {
        let request = {
            id: params.ele.id,
            is_accept: params.is_accept
        };
        const response = await axios.post(sendInvitation, request);
        if (response.data.status) {
            socket.current.emit('invitation-send', {
                sender_id: params.ele.receiver_id,
                receiver_id: params.ele.sender_id,
                is_accept: params.is_accept
            });
            toast.success(response.data.message, toastOptions);
            getInvitations();
            getInvitedUsers({
                receiver_id: params.ele.sender_id,
                is_accept: params.is_accept
            });
            if (params.is_accept) {
                setInvitationModal(false);
            }
        } else {
            toast.error(response.data.message, toastOptions);
        }
    };

    return (
        <>
            <div className="invite-modal">
                <>
                    <h2 className="in-received">Invitation Received</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Time</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invitationData && invitationData.invitation_received?.length > 0 ? (
                                <>
                                    {
                                        invitationData.invitation_received.map((ele, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{ele.sender_name}</td>
                                                    <td>{ele.sender_email}</td>
                                                    <td>{ele.invite_date}</td>
                                                    <td className="in-action">
                                                        {
                                                            ele.is_accept === 1 ? (<span>Accepted</span>) :
                                                                ele.is_accept === 2 ? (<span>Rejected</span>) :
                                                                    (
                                                                        <>
                                                                            <button className="in-accept in-button"
                                                                                onClick={() => acceptOrReject({
                                                                                    ele,
                                                                                    is_accept: 1
                                                                                })}>
                                                                                Accept
                                                                            </button>{' '}
                                                                            <button className="in-reject in-button"
                                                                                onClick={() => acceptOrReject({
                                                                                    ele,
                                                                                    is_accept: 2
                                                                                })}
                                                                            >Reject</button>{' '}
                                                                        </>
                                                                    )
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </>
                            ) : (
                                <tr>
                                    <div className="no-data-found">No Data Found</div>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>

                <>
                    <div className="send-invitation">
                        <h2 className="in-sent">Invitation Sent</h2>
                        <button className="in-invite in-button" onClick={() => setSendInvitationModal(true)}>
                            Invite
                        </button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Time</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invitationData && invitationData.invitation_sent?.length > 0 ? (
                                <>
                                    {
                                        invitationData.invitation_sent.map((ele, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{ele.receiver_name}</td>
                                                    <td>{ele.receiver_email}</td>
                                                    <td>{ele.invite_date}</td>
                                                    <td className="in-action">
                                                        {
                                                            ele.is_accept === 1 ? (<span>Accepted</span>) :
                                                                ele.is_accept === 2 ? (<span>Rejected</span>) :
                                                                    (<span>Pending</span>)
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </>
                            ) : (
                                <tr>
                                    <div className="no-data-found">No Data Found</div>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            </div>
            {/* applied class on modal */}
            <Modal className="invite-details"
                isOpen={isShowSendInvitationModal}
            >
                <div className="invite-modal">
                    <h4 onClick={() => setSendInvitationModal(false)} className="btn-close-h4 send-mdl">
                        <span className="btn-close">X</span>
                    </h4>
                    <SendInvitation setSendInvitationModal={setSendInvitationModal} socket={socket} />
                </div>
            </Modal>
        </>
    );
}

export default Invitation;