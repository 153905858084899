import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import axios from "axios";
import { loginUser } from "../utils";
import { auth, generateUserDocument } from "../firebase/firebase";

export default function WalletConnectBtn() {
  const navigate = useNavigate();

  const toastOptions = {
    position: "bottom-right",
    autoClose: 5000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(async () => {
    let isConnected = localStorage.getItem('wagmi.connected');
    if (isConnected !== 'true') {
      const ipAddress = localStorage.getItem('ip');
      const logEmail = localStorage.getItem('log-email');
      localStorage.clear();
      localStorage.setItem('ip', ipAddress);
      if (logEmail) {
        localStorage.setItem('log-email', logEmail);
      }
      navigate('/metamask');
    }

    const interval = setInterval(() => {
      var metamskAddress = JSON.parse(localStorage.getItem("wagmi.store"));
      let isWagmiConnected = localStorage.getItem('wagmi.connected');
      if (metamskAddress && isWagmiConnected === 'true') {
        toast.success('Wallet connected successfully', toastOptions);
        metamskAddress = metamskAddress.state.data?.account;
        if (metamskAddress) {
          localStorage.setItem('metamask', metamskAddress);
          userIsExitOrNot();
          clearInterval(interval);
        }
      }
    }, 1000);
  }, []);

  const userIsExitOrNot = async () => {
    let metamskAddress = localStorage.getItem('metamask');
    let emailLocalStorage = localStorage.getItem('log-email');
    if (!emailLocalStorage) {
      if (window.location.href.split('?q=')[1]) {
        localStorage.setItem('log-email', window.location.href.split('?q=')[1]);
        emailLocalStorage = localStorage.getItem('log-email');
      }
    }
    const loggedInData = await axios.get(`https://vaultio.aarchik.com/get-user-data?email=${emailLocalStorage}`);
    if (loggedInData && localStorage.getItem('wagmi.connected') === 'true') {
      let email = loggedInData.data?.data?.email;
      let password = loggedInData.data?.data?.password;
      const { data } = await axios.post(loginUser, {
        email: email,
        metamask: metamskAddress,
      });

      if (data.status) {
        var firebaseLogin = auth.signInWithEmailAndPassword(email, password);

        firebaseLogin.then((res) => {
          navigate('/');
        }).catch(async (error) => {
          const { user } = await auth.createUserWithEmailAndPassword(email, password);
          generateUserDocument(user, { displayName: '' });
          auth.signInWithEmailAndPassword(email, password);
          navigate('/');
        })
        localStorage.setItem('chat-app-current-user', JSON.stringify(data.user));
      } else {
        toast.error(data.msg, toastOptions);
      }
    }
  }

  return (
    <>
      <div className="btn-connect-wallet">
        <ConnectButton />
      </div>
    </>
  )
};
