import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { sendInvitation } from "../../utils/APIRoutes";
import "./Invitation.css";

function SendInvitation(props) {
    const [invitation, setInvitationForm] = useState({
        email: "",
        metamask: "",
        invite_from: ""
    });

    const toastOptions = {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
    };

    useEffect(() => {
        let loggedInUser = localStorage.getItem("chat-app-current-user");
        if (loggedInUser) {
            loggedInUser = JSON.parse(loggedInUser);
            setInvitationForm({
                ...invitation,
                invite_from: loggedInUser._id
            })
        }
    }, []);

    const onSubmit = async () => {
        const response = await axios.post(sendInvitation, invitation);
        if (response.data.status) {
            if (response.data?.data) {
                props.socket.current.emit('invitation-send', {
                    sender_id: response.data.data.sender_id,
                    receiver_id: response.data.data.receiver_id,
                });
            }
            props.setSendInvitationModal(false);
            toast.success(response.data.message, toastOptions);
        } else {
            toast.error(response.data.message, toastOptions);
        }
    };

    return (
        <>
            <div className="in-form">
                <div className="in-form-group">
                    <label><b>Email <span className="text-red">*</span></b></label>
                    <input className="in-form-control" placeholder="Enter email" onChange={(e) => {
                        setInvitationForm({
                            ...invitation,
                            email: e.target.value,
                        });
                    }} />
                </div>
                <div className="in-form-group">
                    <label><b>MetaMask Address</b></label>
                    <input className="in-form-control" placeholder="Enter metamask address"
                        onChange={(e) => {
                            setInvitationForm({
                                ...invitation,
                                metamask: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="in-footer">
                    <button type="submit" className="in-button in-invite" disabled={!invitation.email} onClick={onSubmit}>Submit</button>
                </div>
            </div>
        </>
    );
}

export default SendInvitation;