// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
// } from "@mui/material";
import React, { useEffect } from "react";
import AddTemplate from "./AddTemplate";
import { Button } from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Padding } from "@mui/icons-material";

const columns = [
  { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'bgImage', label: 'Background Image', minWidth: 100 },
  {
    id: 'title',
    label: 'Title',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'subTitle',
    label: 'Sub Title',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'right',
  },
];

const rows = [];

export default function ViewAllTemplates() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  useEffect(() => {
    let showData = localStorage.getItem("templateList");
    showData = JSON.parse(showData);
    setData(showData);
  }, []);

  const deleteData = (template) => {
    let delData = template.id;
    const listFilterData = data.filter((ele) => {
      return ele.id !== delData;
    });
    localStorage.setItem("templateList", JSON.stringify(listFilterData));
    setData(listFilterData);
  };

  const editData = (template) => {
    setSelectedCertificate(template);
    setTimeout(() => {
      setShow(true);
    }, 1000);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {!show && (
        <>
          <div style={{display: "flex", justifyContent:"space-between"}}>
            <h2 style={{color: "rgb(25, 118, 210)", fontWeight: "600", letterSpacing: "1px"}}>Template</h2>
            <Button
              onClick={() => {
                setShow(true);
                setSelectedCertificate(null);
              }}
              variant="contained"
              color="primary"
              style={{ margin: "25px", fontWeight: "600"}}
            >
              <AddIcon fontSize="medium" /> Add
            </Button>
          </div>

         <div style={{display: "flex", justifyContent:"flex-end"}}>
         <input
          style={{margin: "25px", 
                  padding: "7px 7px 7px 7px",
                  width: "22%",
                  fontSize: "20px",
                  border: "none",
                  borderBottom: "1px solid",
                }}
           type="search" name="search" id="search-field" placeholder="Search"/>
         </div>

          {/* <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Back Ground Image</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>SubTitle</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((template) => (
                  <TableRow
                    key={template.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {template.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <img
                        width={"40px"}
                        src={template.backgroundImage.split("**")[1]}
                        alt="loading"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {template.fieldsDetails.title}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {template.fieldsDetails.subTitle}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {
                        <DeleteIcon
                          style={{ color: "red", marginRight: "25px" }}
                          onClick={() => {
                            deleteData(template);
                          }}
                        />
                      }
                      {
                        <ModeEditIcon
                          onClick={() => {
                            editData(template);
                          }}
                          style={{ color: "green" }}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div> */}
           <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
      {show && (
        <AddTemplate
          setShow={setShow}
          selectedTemplate={selectedCertificate}
          setData={setData}
        />
      )}
    </>
  );
}

// import * as React from 'react';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';

// const columns = [
//   { id: 'id', label: 'ID', minWidth: 170 },
//   { id: 'bgImage', label: 'Background Image', minWidth: 100 },
//   {
//     id: 'title',
//     label: 'Title',
//     minWidth: 170,
//     align: 'right',
//   },
//   {
//     id: 'subTitle',
//     label: 'Sub Title',
//     minWidth: 170,
//     align: 'right',
//   },
//   {
//     id: 'action',
//     label: 'Action',
//     minWidth: 170,
//     align: 'right',
//   },
// ];

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

// const rows = [];

// export default function StickyHeadTable() {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   return (
    
//     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//       <TableContainer sx={{ maxHeight: 440 }}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column.id}
//                   align={column.align}
//                   style={{ minWidth: column.minWidth }}
//                 >
//                   {column.label}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {rows
//               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               .map((row) => {
//                 return (
//                   <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
//                     {columns.map((column) => {
//                       const value = row[column.id];
//                       return (
//                         <TableCell key={column.id} align={column.align}>
//                           {column.format && typeof value === 'number'
//                             ? column.format(value)
//                             : value}
//                         </TableCell>
//                       );
//                     })}
//                   </TableRow>
//                 );
//               })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[10, 25, 100]}
//         component="div"
//         count={rows.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     </Paper>
//   );
// }